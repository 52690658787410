import React, { useState } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import cx from 'classnames';
import rightArrow from '@plone/volto/icons/right-key.svg';
import downArrow from '@plone/volto/icons/down-key.svg';

function treeFromInputs(choices, value, selectedBranches) {
  var tree = {
    children: {},
    checked: false,
  };

  for (let choice of choices) {
    let label = choice.label.replace(/^\/+|\/+$/g, ''); // trim leading and trailing slashes
    let path = label.split(/(?<!\/)\/(?!\/)/); // split by single slash, but ignore double or more
    let obj = tree;
    let checked = value.some((v) => v.value === choice.value);
    let currentPath = '';
    for (let p of path) {
      currentPath += p;
      if (!(p in obj.children)) {
        obj.children[p] = {
          children: {},
          checked: false,
        };
      }
      obj = obj.children[p];
      obj.path = currentPath;
      obj.checked =
        selectedBranches.includes(currentPath) || obj.checked || checked;
      currentPath += '/';
    }
    obj.value = choice;
  }
  return tree;
}

function valueFromTree(tree) {
  const inner = (tree, layer) => {
    let value = [];
    let selectedBranches = [];
    if (tree.checked || layer === 0) {
      // Only include parent in value if no child is checked to avoid showing all content tagged with parent
      // since parent has to be checked to view children
      let child_checked = false;
      for (let child in tree.children) {
        if (tree.children[child].checked) {
          child_checked = true;
          break;
        }
      }
      if ('value' in tree) {
        if (!child_checked) {
          value.push(tree.value.value);
        }
      } else {
        if ('path' in tree) {
          selectedBranches.push(tree.path);
        }
      }
      for (let child in tree.children) {
        let [newValue, newSelectedBranches] = inner(
          tree.children[child],
          layer + 1,
        );
        value = value.concat(newValue);
        selectedBranches = selectedBranches.concat(newSelectedBranches);
      }
    }
    return [value, selectedBranches];
  };
  return inner(tree, 0);
}

const TreeBranch = (props) => {
  const { label, tree, path, onTreeChange } = props;
  if (Object.keys(tree).length === 0) {
    return <></>;
  }

  if (Object.keys(tree.children).length === 0) {
    return (
      <div className="tree-branch-header">
        <Checkbox
          label={label}
          checked={tree.checked}
          onChange={(e, { checked }) => {
            let t = { ...tree };
            t.checked = checked;
            onTreeChange(t, true);
          }}
        />
      </div>
    );
  }

  var branches = [];
  for (let child in tree.children) {
    branches.push(
      <TreeBranch
        label={child}
        tree={tree.children[child]}
        path={path + '/' + child}
        onTreeChange={(newTree, queryUpdated) => {
          let t = { ...tree };
          t.children[child] = newTree;
          onTreeChange(t, queryUpdated);
        }}
        key={path + '/' + child}
      />,
    );
  }
  if (path === '') {
    return <>{branches}</>;
  }
  return (
    <div>
      <div className="tree-branch-header">
        <Icon name={tree.checked ? rightArrow : downArrow} size="17px" />
        <Checkbox
          label={label}
          onChange={(e, { checked }) => {
            let t = { ...tree };
            t.checked = checked;
            function disableBranch(tree) {
              tree.checked = false;
              for (let child in tree.children) {
                disableBranch(tree.children[child]);
              }
            }
            if (!checked) disableBranch(t);
            onTreeChange(t, 'value' in tree || !checked);
          }}
          checked={tree.checked}
        />
      </div>
      <div className={cx('tree-branch', tree.checked ? 'open' : 'closed')}>
        {branches}
      </div>
    </div>
  );
};

const TreeFacet = (props) => {
  const { facet, choices, onChange, value } = props;
  const [selectedBranches, setSelectedBranches] = useState([]);
  const tree = treeFromInputs(choices, value, selectedBranches);

  return (
    <div className="tree-facet">
      <Header as="h4">{facet.title ?? facet?.field?.label}</Header>
      <div className="entries">
        <TreeBranch
          tree={tree}
          label=""
          path=""
          onTreeChange={(newTree, queryUpdated) => {
            let [newValue, newSelectedBranches] = valueFromTree(newTree);
            setSelectedBranches(newSelectedBranches);
            if (queryUpdated) {
              onChange(facet.field.value, newValue);
            }
          }}
        />
      </div>
    </div>
  );
};

TreeFacet.schemaEnhancer = selectFacetSchemaEnhancer;
TreeFacet.stateToValue = selectFacetStateToValue;
TreeFacet.valueToQuery = selectFacetValueToQuery;

export default TreeFacet;
