/**
 * Tags component.
 * @module components/theme/Tags/Tags
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import {
  getBaseUrl,
  getParentUrl,
  flattenToAppURL,
} from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

/**
 * Tags component class.
 * @function Tags
 * @param {array} tags Array of tags.
 * @param {string} pathname Path to content.
 * @returns {string} Markup of the component.
 */
const Tags = ({ tags }) => {
  const content_id = useSelector((state) => state.content?.data['@id']);
  const pathname = getParentUrl(getBaseUrl(flattenToAppURL(content_id || '')));
  var path_query =
    pathname.length > 0 ? `&path.query=${encodeURIComponent(pathname)}` : '';
  return tags && tags.length > 0 ? (
    <Container className="tags">
      {tags.map((tag) => (
        <Link
          className="ui label"
          to={`/search?Subject=${encodeURIComponent(tag)}${path_query}`}
          key={tag}
        >
          {tag}
        </Link>
      ))}
    </Container>
  ) : (
    <span />
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
Tags.defaultProps = {
  tags: null,
};

export default Tags;
